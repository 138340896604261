import finsightWebUrl from '@/finsight/infrastructure/finsightWebUrl';

const getHomepageMetaSchema = () => ({
  '@type': 'WebSite',
  url: finsightWebUrl.getUrl(),
  name: 'Finsight',
  description: 'New Issue & Historical US ABS Pricing & Volume Data'
});

export default getHomepageMetaSchema;
